@import 'null.scss';





.projects{
   width: 100%;
   height: 100vh;
   
   &__container{
      width: 100%;
      height: 100%;
      background-color: var(--APB-color-grey);
      @media (max-width:992px) {
         padding-top: 68px;
         
         
      }
   }


   &__row{
      height: 100%;
      display: flex;
      align-items: center;
      @media (max-width:992px) {
         flex-direction: column;
      }
   }

   &__column{
      
      @media (max-width:992px) {
      
         width: 100%;
      }
      
      width: 100%;
      img{
         object-fit: cover;
         height: 100%;
         width: 100%;
      }


      
   }
}

.main-column{
   height: 100%;
   
   &__card{
      height: 100%;
      
      position: relative;
      overflow: hidden;
      &::after{
         content: '';
         background-color: rgba(0, 0, 0, 0.437);
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         transition: background-color 0.7s;
         pointer-events: none;
         @media (max-width:992px) {
            
            background-color: rgba(0, 0, 0, 0);
         }
      }
      .card-title{
         bottom: 21%;
         @media (max-width:992px) {
            
            bottom: 40%;
         }
      }
      @media (min-width:993px) {
         &:hover{
            &::after{
               background-color: rgba(0, 0, 0, 0);
            }
            .card-title{
               left: 31%;
               @media (max-width:1240px) {
                  left: 28%;
               }
               &::before{
                  left: -120px;
               }
            }
            .card-info{
               opacity: 1;
            }
            .details{
               padding: 12px 16px;
               bottom: 4%;
            }
         }
      }
      
   }
}
.sub-column{
   height: 100%;
   .card-info{
      bottom: 16%;
   }
   display: flex;
   align-items: center;
   flex-direction: column;
   
   
   &__card{
      height: inherit;
      
      &::after{
         content: '';
         background-color: rgba(0, 0, 0, 0.637);
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         transition: background-color 0.7s;
         pointer-events: none;
         @media (max-width:992px) {
            
            background-color: rgba(0, 0, 0, 0.35);
         }
      }
      position: relative;
      
      width: 100%;
      overflow: hidden;
      @media (min-width:993px){
         &:hover{
            &::after{
               background-color: rgba(0, 0, 0, 0);
            }
            .card-title{
               left: 31%;
               @media (max-width:1240px) {
                  left: 28%;
               }
               &::before{
                  left: -120px;
               }
            }
            .card-info{
               opacity: 1;
            }
            .details{
               padding: 12px 16px;
               bottom: 4%;
            }
         }
      }
      
   }
}


.card-title{
   z-index: 2;
   position: absolute;
   bottom: 28%;
   left: 18%;
   @media (max-width:1240px) {
      left: 12%;
   }
   @media (max-width:992px) {
      left: 50%;
      transform: translateX(-50%);
      width: 100%;text-align: center;
      font-size: 40px;
      line-height: 44px;
      bottom: 40%;
   }
   @media (max-width:768px) {
      font-size: 32px;
      line-height: 44px;
      
   }
   font-weight: 400;
   font-size: 48px;
   line-height: 72px;
   color: var(--APB-color-white);

   transition: left 0.3s;
   &::before{
      transition: left 0.4s;
      position: absolute;
      top: 55%;

      left: -300%;
      content: '';
      width: 95px;
      height: 2px;
      background-color: var(--APB-color-white);
      @media (max-width:992px) {
         display: none;
      }
   }
}


.card-info{
   transition: opacity 0.5s;
   opacity: 0;
   position: absolute;
   bottom: 13%;

   left: 50%;
   transform: translateX(-50%);

   display: flex;
   align-items: center;
   justify-content: space-evenly;
   width: 80%;
   @media (max-width:1240px) {
      width: 95%;
   }
   @media (max-width:992px) {
      display: none;
      
   }
   
   &__name{
      display: flex;
      align-items: center;
      img{
         width: 32px;
         height: 34px;
         margin-right: 10px;
      }
      p{
         font-weight: 400;
         font-size: 20px;
         line-height: 72px;
         color: var(--APB-color-white);
         padding-bottom: 2px;
      }
   }
}


.details{
   transition: all 0.8s;
   padding: 0;
   position: absolute;
   bottom: -100px;
   left: 18%;
   @media (max-width:1240px) {
      left: 4%;
   }

   @media (max-width:992px) {
      z-index: 2;
      opacity: 1;
      padding: 4px 28px;
      bottom: 15%;
      left: 50%;
      transform: translateX(-50%);
   }
   display: flex;
   align-items: center;
   border: 1px solid var(--APB-color-brown);
   img{
      transform: rotate(-90deg);
   }
   p{
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: var(--APB-color-brown);
      text-transform: uppercase;
      padding-right:10px ;
      @media (max-width:992px) {
         font-size: 16px;
      line-height: 22px;
      }
   }
}

.left{
   width: 50%;
   height: 100%;
   @media (max-width:992px) {
      width: 100%;
      height: 33.3333%;
   }
}
.right{
   width: 50%;
   height: 100%;
   @media (max-width:992px) {
      width: 100%;
      height: 66.6666%;
   }
   
}