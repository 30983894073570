@import 'null.scss';


.about{

width: 100%;
height: 100vh;

position: relative;
   &__bg{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -11;
      @media (max-width:992px) {
         position: absolute;
         height: 100vh;
         min-height: 100%;
      }
      img{
         object-fit: cover;
         width: 100%;
         height: 100%;
      }
   }


   &__container{
      z-index: 2;
      position: relative;
      @media (max-width:992px) {
         
         position: relative;
         padding-bottom: 40px;
      }
      padding-top: 200px;
      padding-left: 15%;
      padding-right: 15%;
      @media (max-width:480px ){
         padding-left: 8%;
         padding-right: 8%;
      }
   }

   &__title{

      white-space: pre-line;
            font-weight: 300;
      font-size: 72px;
      line-height: 72px;
      /* or 100% */

      text-transform: uppercase;
      color: var(--APB-color-white);
      padding-bottom: 40px;
      @media (max-width:768px ){
         font-weight: 300;
         font-size: 40px;
         line-height: 40px;
         padding-bottom: 20px;
      }
   }
   &__text{
      
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      color: var(--APB-color-white);
      @media (max-width:768px ){
         font-weight: 400;
         font-size: 16px;
         line-height: 28px;
      }
      }
}