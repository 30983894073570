
@import 'null.scss';


.services{
   
   width: 100%;
   height: 100vh;
   padding-top:180px ;

@media (max-width:992px) {
   padding-top: 0px;
}
   position: relative;
      &__bg{
         position: fixed;
         top: 0;left: 0;
         width: 100%;
         height: 100%;
         z-index: -11;
         @media (max-width:992px) {
            position: absolute;
            
         }
         img{
            object-fit: cover;
            width: 100%;
            height: 100%;
         }
      }
   
   
      &__container{
         z-index: 2;
         padding-bottom: 40px;
         padding-right: 15%;
         padding-left: 15%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         
         @media (max-width:992px) {
            position: relative;
            padding-top: 100px;
         }
         @media (max-width:480px) {
            width: 100%;
            padding-right: 8%;
         padding-left: 8%;
         }
         
      }
      &__body{
         

         
      }
      &__title{
        
         background-color: var(--APB-color-white);
         border: 1px solid var(--APB-color-grey) ;
         h2{
            color: var(--APB-color-grey);
            font-weight: 300;
            font-size: 72px;
            line-height: 72px;
            /* identical to box height, or 100% */
            
            text-transform: uppercase;


            @media (max-width:992px) {
               font-weight: 300;
               font-size: 40px;
               line-height: 40px;
               text-align: center;
               
            }
         }
         @media (max-width:992px) {
            padding: 24px ;
            
         }
         padding: 42px 0 48px 50px;
      }
   }

.service-development{
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--APB-color-white);

   border-right: 1px solid var(--APB-color-grey) ;
   border-left: 1px solid var(--APB-color-grey) ;
   border-bottom: 1px solid var(--APB-color-grey) ;



   @media (max-width:992px) {
      flex-direction: column;
      
   }
   &__bg{
      transition: opacity 0.3s;
      opacity: 0;
      pointer-events: none;
      background-color: var(--APB-color-grey);
      position: absolute;
      z-index: -1;
      width: 110%;
      height: 110%;
      box-shadow: 0 0 2px 0px  white;
   }
   &__card{
      position: relative;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 57px 0;
      z-index: 2;
      border-right: 1px solid var(--APB-color-grey) ;
      @media (max-width:992px) {
         width: 100%;
         border-right:none;
         border-bottom: 1px solid var(--APB-color-grey) ;
         flex-direction: row;
         padding: 16px 0;
      }
      &:last-child{
         border-right:none;
         border-bottom:none;
      }

      &:hover{
         
         .service-development__title{
            color: var(--APB-color-white);
         }
         
         .service-development__bg{
            opacity: 1;
            
         }
         

      }
   }
   &__logo{
      img{
         object-fit: cover;
      }
      @media (max-width:992px) {
         width: 40%;
         display: flex;
         align-items: center;
         justify-content: flex-end;
      }
      
   }

   &__title{
      transition: color 0.3s;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      text-align: center;

      color: #000000;
      @media (max-width:992px) {
         font-size: 16px;
         line-height: 22px;
         padding-left: 10px;
         display: flex;
         align-items: center;
         justify-content: flex-start;
         width: 60%;
         text-align: left;
         padding-right: 5px;
      }
   }


   
}


.service-bottom{
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--APB-color-white);
   
   border-right: 1px solid var(--APB-color-grey) ;
   border-left: 1px solid var(--APB-color-grey) ;
   border-bottom: 1px solid var(--APB-color-grey) ;
   @media (max-width:992px) {
      flex-direction: column;
      
   }
   &__bg{
      transition: opacity 0.3s;
      opacity: 0;
      pointer-events: none;
      background-color: var(--APB-color-grey);
      position: absolute;
      z-index: -1;
      width: 110%;
      height: 110%;
      box-shadow: 0 0 2px 0  white;
   }
   &__card{
      position: relative;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 57px 0;
      z-index: 1;
      border-right: 1px solid var(--APB-color-grey) ;
      @media (max-width:992px) {
         flex-direction: row;
         padding: 16px 0;
         width: 100%;
         border-right:none;
         border-bottom: 1px solid var(--APB-color-grey) ;
         
      }
      &:last-child{
         border-right:none;
         border-bottom:none;
      }
      &:hover{
         
         .service-bottom__title{
            color: var(--APB-color-white);
         }
         
         .service-bottom__bg{
            opacity: 1;
         }
         

      }
      
   }
   &__logo{
      img{
         object-fit: cover;
      }
      @media (max-width:992px) {
         width: 40%;
         display: flex;
         align-items: center;
         justify-content: flex-end;
      }
   }

   &__title{
      transition: color 0.3s;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      text-align: center;

      color: #000000;

      @media (max-width:992px) {
         font-size: 16px;
         line-height: 22px;
         padding-left: 10px;
         width: 60%;
         display: flex;
         align-items: center;
         justify-content: flex-start;
         text-align: left;
         padding-right: 5px;
      }
      
   }
}