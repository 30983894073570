

@import 'null.scss';

.header{
   font-family: 'Manrope', sans-serif;
   position: absolute;
   top: 15px;
   width: 70%;
   left: 50%;
   transform: translateX(-50%);
   z-index: 99999;
   @media (max-width:992px ){
      top: 0px;
      width: 100%;
      position: fixed;
   }
  
}
 .header__container{
   

 }

.header-contacts{
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-bottom: 10px;
   padding-right: 10px;
   width: 100%;
   a{
      color: var(--APB-color-white);
   }
   @media (max-width:992px ){
      display: none;
   }

   &__phone{

      margin-right: 25px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      text-align: center;
      @media (max-width:1360px ){
         font-size: 16px;
      }
   }

   &__mail{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      @media (max-width:1360px ){
         font-size: 16px;
      }
   }
}


.header-main{
   background-color: var(--APB-color-grey);
   a{
      color: white;
   }

   padding: 24px 30px;
   @media (max-width:1360px ){
      padding: 16px;
   }
   &__container{
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   &__logo{
      object-fit: cover;
      width: 20%;
      @media (max-width:1200px ){
        
         img{
            
            width: 97px;
         }
      }
      
      @media (max-width:992px ){
         width: 50%;
      }
      
   }
   &__links{
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      color: var(--APB-color-white);
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-transform: capitalize;
      
      width: 70%;
     
      @media (max-width:1360px ){
         padding: 0 2%;
      }
      @media (max-width:1280px ){
         font-size: 15px;
         padding: 0 1%;
      }

      @media (max-width:1100px ){
         font-size: 14px;
      }
      @media (max-width:992px ){
         padding: 0;
         font-size: 15px;
         flex-direction: column;
         width: 100%;
      }
      :not(:last-child){
         padding-right: 12px;
         @media (max-width:992px ){
            padding: 0;
            padding-bottom: 10px;
         }
      }
   }

   &__right-side-comp{
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 80%;
      @media (max-width:1100px ){
         width: 90%;
      }
      @media (max-width:992px ){
         display: none;
         
      }
   }

   &__right-side-mob{
      display: none;
      @media (max-width:992px ){
         display: flex;
         position: absolute;
         flex-direction: column;
         padding: 100px;
         
         height: 310px;
         left: 0;
         width: 100%;
         background-color: var(--APB-color-grey);
         top:  -350px;
      }
      @media (max-width:480px ){
         padding-left: 50px;
         padding-right: 50px;
      }

   }
   &__link{
      white-space: nowrap;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      position: relative;
      @media (max-width:992px ){
         padding-bottom: 10px;
         
      }
      @media (min-width:993px ){
         &:hover{
            &::after{opacity: 1;}
         }
         
      }
      
      &.active{
         &::after{opacity: 1;}

      }
      &::after{
         opacity: 0;
         transition: opacity 0.3s;
         content: '';
         background-color: var(--APB-color-white);
         height: 1px;
         width: 100%;
         bottom: -2px;
         left: -6px;
         position: absolute;
      }
      &:last-child::after{
         width: 100% + 20;
      }
   }

}
.form-btn-container{
   @media (max-width:992px ){
      margin: 0 auto;
      width: 50%;
   }
   @media (max-width:574px ){
      
      width: 100%;
   }
   &__btn{
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      padding: 14px 28px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: var(--APB-color-white);
      background-color: var(--APB-color-brown);
      white-space: nowrap;
      @media (max-width:1360px ){
         padding: 14px 20px;
      }
      @media (max-width:1200px ){
         font-size: 14px;
         padding: 8px 12px;
      }
   }
}

.right-side-mob-burger{
   display: none;
   @media (max-width:992px ){
      display: block;
   }
   cursor: pointer;
   position: absolute;
   
   opacity: 1;
   top: 50%;
   transform: translateY(-50%);
   right: 16px;
}
.right-side-mob__exit{
   cursor: pointer;
   position: absolute;
   right: 16px;
   top: 14px;
}

.right-side-mob{
   transition: all 0.5s;
   
   pointer-events: none;
   overflow: hidden;
   &.active{
      pointer-events: all;

      top: 0;
      padding-top: 60px;
         //height: 310px;
   }

   &.noactive{
         animation: opac 0s forwards 0.5s;
         //height: 310px;
   }
}
@keyframes opac {
   0%{
      opacity: 0;
   }
   100%{
      opacity: 0;
   }
}