@import 'null.scss';


.investments{

width: 100%;
height: 100vh;


   &__bg{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      width: 100%;
      
      height: 100%;
      z-index: -11;
      img{
         object-fit: cover;
         width: 100%;
         height: 100%;
      }
   }


   &__container{
      padding-top:180px ;
      min-height: 841px;
      height: 100vh;

@media (max-width:992px) {
   padding-top: 100px;
}
      position: relative;
      z-index: 2;
      width: 100%;
      //padding:0 15%;
      padding-left: 15%;
      padding-right: 15%;
      

      @media (max-width:480px) {
         
         padding-right: 8%;
         padding-left: 8%;

      }

   }

   &__columns{
     
     display: flex;
     @media (max-width:992px) {
      flex-direction: column;
   }
     //align-items: flex-start;
     //justify-content: space-between;
     
   }
   &__column{
      max-height: 550px;
      display: flex;
      width: 50%;
      background-color: var(--APB-color-white);
      flex-direction: column;
      justify-content: space-between;

      @media (max-width:992px) {
         width: 100%;
         height: auto;
      }
   }
   &__title{
      padding-left:8% ;
      white-space: pre-line;
      
      padding-top: 10%;
      padding-bottom: 7%;
      width: 100%;
      border-top: 1px solid #f5f5f5 ;
      border-left: 1px solid #f5f5f5 ;
      border-right: 1px solid #f5f5f5 ;
      @media (max-width:992px) {
         font-size: 40px;
         line-height: 40px;
         padding-top: 24px;
         padding-bottom: 10px;
         padding-left: 30px;
         padding-right: 30px;
      }
      h2{
         
         padding-bottom: 4%;
         color: var(--APB-color-grey);
         font-weight: 300;
         font-size: 72px;
         line-height: 72px;
         /* identical to box height, or 100% */
         
         text-transform: uppercase;
         @media (max-width:1300px) {
            //padding-top: 13% ;
            font-size: 50px;
            line-height: 50px;
         }


         @media (max-width:992px) {
            font-size: 40px;
            line-height: 40px;
            padding-bottom: 10px;
         }
      }
      p{
         font-weight: 400;
         font-size: 18px;
         line-height: 36px;
         color: var(--APB-color-grey);
         padding-right:10px ;
         @media (max-width:992px) {
            font-size: 16px;
            line-height: 28px;
            padding-right: 0;
         }
         
      }

   }
}


.investments-info{
   display: flex;
   justify-content: center;
   border: 1px solid #f5f5f5 ;
   height: 30%;
   @media (max-width:768px) {
      flex-direction: column;
      
   }
   &__bg{
      transition: opacity 0.3s;
      opacity: 0;
      pointer-events: none;
      background-color: var(--APB-color-grey);
      position: absolute;
      z-index: -1;
      top: -5%;
      width: 110%;
      height: 110%;
      box-shadow: 0 0 2px 0px  white;
   }
   &__card{
      justify-content: center;
      position: relative;
      width: 33.3333%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px;
      @media (max-width:992px) {
         
      }
      @media (max-width:768px) {
         width: 100%;
         border-right:none;
         border-bottom: 2px solid #f5f5f5 ;
      }
      z-index: 2;
      border-right: 2px solid #f5f5f5 ;
      
      &:last-child{
         border-right:none;
      }

      &:hover{
         
         .investments-info__text{
            color: var(--APB-color-white);
         }
         
         .investments-info__bg{
            opacity: 1;
            
         }
         

      }
   }
   &__logo{
      img{
         object-fit: cover;
      }
   }

   &__title{
      padding-bottom: 9px;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      

      color: var(--APB-color-brown);
      @media (max-width:1300px) {
         font-size: 18px;
         line-height: 22px;
      }
   }
   &__text{
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;
      color: var(--APB-color-grey);
      @media (max-width:1300px) {
         font-size: 14px;
         line-height: 18px;
      }
   }

   
}


.investments__column-img-container{
   width: 100%;
   height: 100%;
   img{
      object-fit: cover;
      height: 100%;
      width: 100%;
   }


   @media (max-width:992px) {
      height: 350px;
   }

   @media (max-width:768px) {
      height: 150px;
   }
}

.scroll-me{
   
   left: 50%;
   transform: translateX(-50%);
   position: absolute;
   bottom: 15px;
   z-index: 9999;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   img{
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      animation: scrollme 0.5s infinite;
   }

   &__img{
      height: 45px;
      width: 45px;
      position: relative;
      
   }
   p{
      padding-top: 4px;
      color: var(--APB-color-white);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;
   }
}


@keyframes scrollme {
   0%{
      top: 5px;

   }

   50%{
      top:0px;
   }
   100%{


      top: 5px;

   }
}



.investments-options{
   padding: 40px 15% 24px 15%;
   @media (max-width:992px) {
      background-color: #f5f5f5;
      padding-bottom: 0;
   }
   @media (max-width:768px) {
      padding: 40px 8% 0px 8%;
   }
   &__title{
      font-weight: 300;
      font-size: 48px;
      line-height: 72px;
      color: var(--APB-color-grey);
      white-space: pre-line;
      padding-bottom: 40px;

      @media (max-width:992px) {
         font-size: 24px;
         line-height: 33px;
         text-align: center;
      }
      
   }


   &__main-info{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 60px;
      @media (max-width:992px) {
         
         padding-bottom: 0;
      }

      @media (max-width:768px) {
         flex-direction: column;
      }
   }

   &__main-title{
      white-space: pre-line;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: var(--APB-color-grey);
      width: 25%;
      @media (max-width:992px) {
         font-size: 14px;
         line-height: 19px;
         
      }

      @media (max-width:768px) {
         width: 100%;
         text-align: center;
         br{
            display: none;
         }
         padding-bottom: 20px;
      }
   }
   &__main-text{
      white-space: pre-line;
      width: 75%;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      color: var(--APB-color-grey);
      @media (max-width:992px) {
         font-size: 14px;
line-height: 19px;
      }

      @media (max-width:768px) {
         width: 100%;
         
      }
      :first-child{
         padding-bottom: 32px;
      }
   }
}


.only-options{
   @media (max-width:992px) {
      display: none;
   }
}
.only-options__column{
   
   font-weight: 400;
font-size: 18px;
line-height: 24px;

   text-align: left;
   color: var(--APB-color-grey);
   padding-right: 10px;
   &.column-title{
      white-space: pre-line;
      font-weight: 700;
font-size: 16px;
line-height: 22px;
color: var(--APB-color-grey);
   }
}
.only-options__row{
   
   height: 90px;
   :first-child{
      width: 25%;
   }
   &::after{
      content: '';
      width: 80%;
      left: 10%;
      position: absolute;
      height: 2px;
      background-color: #f5f5f5;
   }
}
.only-options__table{
   width: 100%;
   padding-bottom: 40px;
}


.only-options__thead-row{
   th{
      text-align: left;
      position: relative;
   }



}


.only-options__th-title{
   position: absolute;
   left: 40px;
   top: 50%;
   transform: translateY(-50%);
   font-weight: 700;
font-size: 16px;
line-height: 22px;
color: var(--APB-color-grey);

white-space: nowrap;
@media (max-width:1300px) {
   font-size: 14px;
}


}


.only-options__btn{
   border: 1px solid var(--APB-color-brown);
   padding: 10px 0;
   cursor: pointer;
   width: 300px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 600;
font-size: 20px;
line-height: 28px;
color: var(--APB-color-brown);
   img{
      margin-left: 10px;
   }
      
   margin-bottom: 40px;
}

.th-options-empty{
   opacity: 0;
}



.options-mobile{
   padding-top: 60px;
   padding-bottom: 5px;
   width: 100%;
   padding-left: 10%;
   padding-right: 10%;
   background-color: #f5f5f5;
   display: none;
   @media (max-width:992px) {
      display: block;
   }


   .only-options__thead-row {
      th{
         width:100%;
         text-align: center;
         padding-top: 20px;
         padding-bottom: 20px;
         img{
            padding-right: 30px;
         }
         
      }
      
   }

   .only-options__th-title{
      left: 50%;
      white-space: normal;
      width: 120px;
      text-align: center;
      
   }



   .investor-title{
      width: 70px;
   }


   .only-options__table{
      padding-bottom: 10px;
      
      
   }


   .column-title{
      padding-left: 10px;
   }


   .only-options__btn{
      
      margin-bottom: 0px;

      @media (max-width:480px) {
         
         width: 95%;
         font-size: 18px;
         padding-left: 5px;
         img{
            margin-right: -5px;
         }
      }
   }


   &__card{
      background-color: var(--APB-color-white);
      margin-bottom: 60px;
      padding-bottom: 30px;
   }
}