



@import 'null.scss';


.contacts{

   width: 100%;
   height: 100vh;
 
   position: relative;
      &__bg{
         position: fixed;
         width: 100%;
         height: 100%;
         z-index: -11;
         top: 0;
         left: 0;
         will-change: transform;

         @media (max-width:992px) {
            position: absolute;
            
         }
         img{
            object-fit: cover;
            width: 100%;
            height: 100%;
         }
      }
   
   
      &__container{
         
         padding-top:180px ;
         padding-bottom: 40px;
         padding-right: 15%;
         padding-left: 15%;
         @media (max-width:992px) {
            position: relative;
            padding-top: 100px;
         }
         z-index: 2;
         
         display: flex;
         flex-direction: column;
         justify-content: center;
         @media (max-width:480px) {
            padding-right: 8%;
         padding-left: 8%;
         }
      }
      &__body{
        
      }



      &__title{
         padding: 50px 0 10px 50px;
         background-color: var(--APB-color-white);
         font-weight: 300;
font-size: 72px;
line-height: 72px;
/* identical to box height, or 100% */

text-transform: uppercase;
color: var(--APB-color-grey);

@media (max-width:992px) {
   font-weight: 300;
font-size: 40px;
line-height: 40px;
/* identical to box height, or 100% */
padding: 24px 8px;
text-align: center;
}
      }

      &__text{
         padding-left: 50px;
         padding-bottom: 20px;
         color: var(--APB-color-grey);
         background-color: var(--APB-color-white);

         font-weight: 400;
         font-size: 18px;
         line-height: 36px;
         @media (max-width:992px) {
            font-size: 16px;
            line-height: 28px;
            padding-left: 29px;
            padding-right: 29px;
         }
      }


      &__bottom{
         height: 100px;
         color: var(--APB-color-grey);
         background-color: var(--APB-color-white);
         display: flex;
         align-items: center;
         justify-content: space-between;
         @media (max-width:768px) {
            flex-direction: column;
            height: auto;
         }
      }
      a{
         
         color: var(--APB-color-brown);
         
font-weight: 700;
font-size: 18px;
line-height: 25px;
/* identical to box height */

text-align: center;
@media (max-width:992px) {
   line-height: 22px;
   font-size: 16px;
}
      }

      &__adress{
         width: 33.3333%;
         color: var(--APB-color-brown);
         white-space: pre-line;
font-weight: 700;
font-size: 18px;
line-height: 25px;
@media (max-width:992px) {
   line-height: 22px;
   font-size: 16px;
}
@media (max-width:768px) {
   width: 100%;
   border-bottom: 2px solid #f5f5f5;
   padding: 20px 8px;
}
/* identical to box height */

text-align: center;
border-left: 2px solid #f5f5f5;
border-right: 2px solid #f5f5f5;
height: 100%;
display: flex;align-items: center;
justify-content: center;
      }


      &__phone{
         width: 33.3333%;
         text-align: center;
         @media (max-width:768px) {
            width: 100%;
            border-bottom: 2px solid #f5f5f5;
            padding: 20px 8px;
         }
      }
      &__mail{
         width: 33.3333%;
         text-align: center;
         @media (max-width:768px) {
            width: 100%;
            padding: 20px 8px;
         }
      }
   }

.contacts__map{
   background-color: #f5f5f5;
   height: 250px;
   iframe{
      top: 0;left: 0;
      position: absolute;
      width: 100%;
      height: 250px;
      border: 0;
      z-index: 2;
      
   }
   position: relative;
      




      .lds-ripple {
         z-index: 0;
         display: inline-block;
         position: absolute;
         width: 80px;
         height: 80px;
         top: 50%;
         left: 50%;
         transform: translate(-50%,-50%);
         
      }
      .lds-ripple div {
         
         

      position: absolute;
      border: 4px solid var(--APB-color-brown);
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
      animation-delay: -0.5s;
      }
      @keyframes lds-ripple {
      0% {
         top: 36px;
         left: 36px;
         width: 0;
         height: 0;
         opacity: 0;
      }
      4.9% {
         top: 36px;
         left: 36px;
         width: 0;
         height: 0;
         opacity: 0;
      }
      5% {
         top: 36px;
         left: 36px;
         width: 0;
         height: 0;
         opacity: 1;
      }
      100% {
         top: 0px;
         left: 0px;
         width: 72px;
         height: 72px;
         opacity: 0;
      }
      }
}
