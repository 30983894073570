@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

*{
   //чистим все отступы и границы
   padding: 0;
   margin: 0;
   border: 0;

}
*,*:before,*:after{
   //меняет подсчет ширины блока
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
}

//убираем линии обводки в некоторых браузерах
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}


//перечисляем некоторые теги и добавляем значение block
nav,footer,header,aside{display: block;}

html,body{
   //уравнивает поведение тегов для разных браузеров
   height: 100%;
   width: 100%;
   font-size: 100%;
   line-height: 1;
   font-size: 14px;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   
}


//просим наследовать теги семейство шрифта
input,button,textarea{font-family: inherit; }


//работаем с нюанасами некоторых браузеров

input::-ms-clear{display: none;}
button{cursor: pointer;
   padding: 0;
   border: none;
   font: inherit;
   color: inherit;
   background-color: transparent;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}

h1,h2,h3,h4,h5,h6{font-size: inherit;font-weight: 400;}

a{
   color: transparent;
}

//---------------------------------------







body {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   }


   :root {
      --APB-color-black: #000;
      --APB-color-lightGrey: #F5F5F5;
      --APB-color-white: #ffffff;
      --APB-color-blue: #003d76;
      --APB-color-grey: #2F3649;
      --APB-color-brown: #D2A181;
      --APB-color-light-red: #E40606;
      --APB-color-bg: #16192B;
   }




   ::-webkit-scrollbar {
      width: 0px; /* ширина для вертикального скролла */
      height: 0px; /* высота для горизонтального скролла */
      background-color: #e9e9e9;
  }
  
  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
      background-color: var(--APB-color-brown);
      border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background-color: var(--APB-color-brown);
      
  }