


.footer{
   position: fixed;
   bottom: 0;
   width: 100%;
   z-index: 99999;
   background-color: var(--APB-color-grey);

   &__container{
      padding-left: 15%;
      padding-right: 15%;
      padding-bottom: 16px;
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width:992px) {
         padding-left: 16px;
      padding-right: 16px;
      }

      a {
         img{

         }
         
      }
   }
}